<template>
  <div>
    <b-card
      v-for="item in localData"
      :key="item.id"
      header-tag="header"
      header-bg-variant="primary"
    >
      <template #header>
        <h6 class="mb-0 text-white">
          {{ item.users[0].name }}
        </h6>
        <h6 class="mb-0 text-white">
          {{ item.created_at }}
        </h6>
      </template>
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="12">
            <span v-html="item.konten" />
          </b-col>
        </b-row>
      </b-form>
      <template #footer>
        <div>
          <small v-if="item.inbox_lampiran.length > 0" class="text-muted"
            >Attachment ({{ item.inbox_lampiran.length }})</small
          >
          <b-button variant="danger mr-1 float-right" @click="hapus(item.id)">
            Hapus
          </b-button>
          <b-button variant="success mr-1 float-right" @click="ubah(item)">
            Edit
          </b-button>
        </div>
        <div v-if="item.inbox_lampiran.length > 0">
          <div
            v-for="row in item.inbox_lampiran"
            :key="row.id"
            style="cursor: pointer"
          >
            <small @click="download(row.nama_file)">{{ row.nama_file }}</small>
          </div>
        </div>
      </template>
    </b-card>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="primary"
            >Balas</b-button
          >
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div id="quill-toolbar">
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" value="" />
              <button class="ql-align" value="center" />
              <button class="ql-align" value="right" />
              <button class="ql-align" value="justify" />
              <select class="ql-font">
                <option selected />
                <option value="serif" />
                <option value="monospace" />
              </select>
            </div>
            <quill-editor
              v-model="konten"
              :options="editorOption"
              style="min-height:300px;"
            />
          </b-card-body>
          <b-card-footer>
            <b-row v-for="(x, y) in lampiran" :key="y" class="pb-2">
              <b-col sm="11">
                <b-form-file
                  v-model="x.lampiran"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-col>
              <b-col sm="1">
                <b-button variant="danger" @click="hapusLampiran(y)"
                  >X</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <b-button variant="warning" @click="tambahLampiran"
                  >Tambah Lampiran</b-button
                >
              </b-col>
            </b-row>
            <b-row class="text-center">
              <b-col sm="12">
                <b-button @click="kirim" variant="primary mr-1">Kirim</b-button>
                <!-- <b-button variant="outline-primary">Batal</b-button> -->
              </b-col>
            </b-row>
          </b-card-footer>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormFile,
  // BFormGroup,
  // BFormInput,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardFooter,
  BCardBody,
  // BCardText,
  // BCardGroup,
  BCollapse,
  VBToggle,
  VBModal,
  // BSpinner,
} from 'bootstrap-vue'
import { api, upload, download } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BForm,
    BFormFile,
    // BFormGroup,
    // BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardFooter,
    BCardBody,
    // BCardText,
    // BCardGroup,
    BCollapse,
    quillEditor,
    // BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      theme: 'snow',
      placeholder: 'Ketik disini',
    }
    return {
      editorOption,
    }
  },
  data() {
    return {
      localData: [],
      optionsLocal: null,
      user_id: null,
      biodata_id: null,
      topik_id: null,
      konten: null,
      lampiran: [{ value: '' }],
    }
  },
  async mounted() {
    this.$root.$on('reloadListener', () => {
      this.reload()
    })
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.user_id = userData.id
    this.biodata_id = userData.biodata_id
    this.reload()
  },
  methods: {
    hapusLampiran(index) {
      this.lampiran.splice(index, 1)
    },
    tambahLampiran() {
      this.lampiran.push({
        value: '',
      })
    },
    ubah(param) {
      const tmpData = {
        id: param.id,
        konten: param.konten,
        topik_id: param.topik_id,
        lampiran: param.inbox_lampiran,
      }
      this.$root.ubahInboxPesan(tmpData)
    },
    async hapus(inboxPesanId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
          okTitle: 'Delete',
          okVariant: 'danger',
        })
        .then(async value => {
          if (value) {
            const tmpData = {
              id: inboxPesanId,
              deleted: true,
            }
            await api().patch('inbox', tmpData)
            this.makeToast('success', 'Sukses', 'Data berhasil dihapus')
            this.reload()
          }
        })
    },
    reset() {
      this.konten = null
      this.lampiran = [{ value: '' }]
    },
    async reload() {
      const result = await api().get(`inbox/${this.$route.params.id}`)
      this.localData = result.data
      if (this.localData) {
        this.topik_id = this.localData[0].topik_id
      }
    },
    async kirim() {
      const tmpData = {
        topik_id: this.topik_id,
        user_id: this.user_id,
        konten: this.konten,
        root_topik_id: this.$route.params.id,
      }
      const tempFiles = []
      if (this.lampiran) {
        this.lampiran.forEach(element => {
          const formData = new FormData()
          if (element.lampiran) {
            formData.append('file', element.lampiran)
            tempFiles.push(
              upload().post(`inbox/${this.$route.params.id}/upload`, formData),
            )
          }
        })
      }
      if (tempFiles) {
        let promises
        try {
          promises = await Promise.all(tempFiles)
        } catch (error) {
          this.makeToast('danger', 'Error', error, true)
        }
        const tempArray = []
        promises.forEach(element => {
          tempArray.push(element.data.filename)
        })
        tmpData.lampiran = tempArray
      }
      try {
        await api().post('inbox', tmpData)
        this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
        this.reset()
        this.reload()
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.message, true)
      }
    },
    async download(filename) {
      try {
        this.btnDownloadDisabled = true
        const result = await download().get(
          `inbox/download/${this.$route.params.id}/${filename}`,
        )
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
<style>
.ql-container {
  min-height: inherit;
}
.text-white {
  color: white;
}
.ql-container {
  min-height: inherit;
}
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
</style>
